import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import './styles.scss'

const rowHeight = 116 // How tall is a table row in pixels. Used to calculate maxHeight

export const OrderingInformation = ({ data, className, id, noTitle, align }) => {
  const [expanded, setExpanded] = useState([]);

  const toggleExpanded = (name) => {
    let updatedExpanded = expanded.filter(exp => exp !== name)
    if (updatedExpanded.length === expanded.length) {
      updatedExpanded.push(name)
    }
    setExpanded(updatedExpanded)
  }

  return (
    <div className={`ordering-information ${className}`} id={id}>
      {/* {
        !noTitle &&
        <h2 className="mt-0 mb-3">Ordering Information</h2>
      } */}
      {
        data.map((table, tableInd) => {
          let isExpanded = expanded.find(exp => exp === `${table.closedName}${table.closedDescription}`) || !table.closedName
          let maxHeight = !isExpanded ? 0 : (rowHeight + 35) * table.rowData.length
          return (
            <div key={tableInd} className="mb-2 table-responsive table-transition-time" >
              {
                table.closedName &&
                <button onClick={() => toggleExpanded(`${table.closedName}${table.closedDescription}`)} className="p-0 clickable-header">
                  <div className="closed-name row no-gutters">
                    <div className="col-sm-11">
                      <b className="mr-1">{table.closedName}</b>
                      {table.closedDescription}
                    </div>
                    <div className="col-sm-1 text-right">
                      <div className="show-table-button">
                        <FontAwesomeIcon icon={faChevronDown} className={isExpanded ? 'expanded' : 'collapsed'} />
                      </div>
                    </div>
                  </div>
                </button>
              }
              <div className="table-wrapper" style={{ maxHeight, overflow: 'auto' }}>
                <table className={`table table-striped table-bordered table-hover mb-0 ${expanded ? 'expanded' : ''}`}>
                  <thead>
                    <tr>
                      {
                        table.header.map(({ name }, headerInd) => {
                          return <th key={headerInd} className={`text-white text-${align || "center"}`}>{name}</th>
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      table.rowData.map((row, rowInd) => {
                        return (
                          <tr key={rowInd}>
                            {row.cellData.map((cell, cellInd) => {
                              let isHex = cell.match(/#\b[0-9A-F]{6}\b/gi)
                              return (
                                <td key={cellInd} className={`text-${align || 'center'}`}>
                                  {isHex ?
                                    <svg height="20" width="20">
                                      <circle cx="9" cy="8" r="8" fill={cell} stroke="#000" strokeWidth={cell === "#FFFFFF" ? ".5px" : "0px"} />
                                    </svg>
                                    :
                                    cell.split('\n').map((line, ind) => {
                                      return (
                                        <span key={ind}>
                                          {ind > 0 && <br />}
                                          {line}
                                        </span>
                                      )
                                    })
                                  }
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default OrderingInformation
import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import OrderingInformation from "../components/OrderingInformation"
import Seo from "../components/Seo"
import { BASE_BREADCRUMBS } from '../constants'

const PatentsPage = ({ data }) => {
  let tableData = {
    header: [
      { name: "Typenex Product" },
      { name: "Patent Number" }
    ],
    rowData: []
  }
  if (data) {
    data.allPatents.nodes?.forEach(patent => {
      let prodInd = tableData.rowData.findIndex(row => row.cellData[0] === patent.product_name)
      if (prodInd >= 0) {
        tableData.rowData[prodInd].cellData[1] += `\n${patent.patent_number}`
      } else {
        tableData.rowData.push({
          cellData: [
            patent.product_name,
            patent.patent_number
          ]
        })
      }
    })
  }
  return (
    <Layout
      pageTitle="Patents"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/patents", name: "Patents" }]}
    >
      <Seo
        title="Patents | Typenex Medical"
        description="Learn about Typenex Patents."
      />
      <OrderingInformation noTitle={true} data={[tableData]} align={'left'} />
      <div className="mt-3">
        <p>Typenex continues to innovate in all areas of potential interest to our valued customers, and has obtained the additional patents listed below:</p>
        <ul>
          <li><b>D649900:</b> Identification insert for recipient identification wrist band</li>
          <li><b>US 7,481,370:</b> Removable patient identification strap for blood recipient verification</li>
          <li><b>US 8,099,889:</b> Recipient verification systems and methods of use, including patient identification</li>
          <li><b>US 8,028,450:</b> Recipient verification systems and methods of use, including recipient identification</li>
        </ul>
        <p>To view our trademarks
          <Link to="/trademarks/" className="ml-1">click here!</Link>
        </p>
      </div>
    </Layout>
  )
}

export default PatentsPage

export const query = graphql`
  query {
    allPatents {
      nodes {
        product_name
        patent_number
      }
    }
  }
`
